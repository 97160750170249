<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="history">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{ modules[currentModule].name }}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule, currentService.slug]">Liste
            des {{ currentService.name }}</a></li>
          <li *ngIf="currentService.sub" class="breadcrumb-item">
            <a
              [routerLink]="['/'+currentModule,  currentService.slug, currentService.sub.slug]">{{ currentService.sub.name }}</a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-striped ppp-datatable">
            <thead>
            <tr>
              <th>Quoi</th>
              <th>ID</th>
              <th>Date</th>
              <th>Action</th>
              <th>Qui</th>
              <th>Changements</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of currentItem">
              <td>{{ getTranslation(item.auditable_type) }}</td>
              <td>{{ item.auditable_id }}</td>
              <td>{{ item.created_at | date:'dd/MM/y HH:mm' }}</td>
              <td>{{ getTranslation(item.action) }}</td>
              <td>{{ item.username }}</td>
              <td>
                <div *ngFor="let key of objectKeys(item.handle_audited_changes)">
                  <strong>{{ getTranslation(key) }} :</strong>
                  <ul *ngIf="Array.isArray(item.handle_audited_changes[key]); else notArray">
                    <li *ngFor="let val of item.handle_audited_changes[key]">
                      {{ getTranslation(val) || '""' }}
                    </li>
                  </ul>
                  <ng-template #notArray>
                    {{ getTranslation(item.handle_audited_changes[key]) }}
                  </ng-template>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <a [routerLink]="['/'+currentModule, currentService.slug]" type="button"
           class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
          Retour
        </a>
      </div>
    </div>
  </div>

</ng-container>
