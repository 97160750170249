<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/tire-models']">Profils de pneus</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else elseItem">
            <li class="breadcrumb-item active"><a href="javascript:;">{{editMode.data.name}}</a></li>
          </ng-container>
          <ng-template #elseItem>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouveau profil de pneus</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <h4 class="card-title">Édition d'un profil de pneus : <strong class="description">{{editMode.data.name}}</strong></h4>
          </ng-container>
          <ng-template #templateElse>
            <h4 class="card-title">Création d'un nouveau profil de pneus</h4>
          </ng-template>
        </div>


        <div class="card-body" *ngIf="editFormGroup">
          <form class="form" [formGroup]="editFormGroup">
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="name" class="bmd-label-floating">Dénomination</label>
                <input type="text" class="form-control" id="name" formControlName="name">
              </p>
            </div>


            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.title.errors }">
                <label for="title" class="bmd-label-floating">Titre</label>
                <input type="text" class="form-control" id="title" formControlName="title">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.description.errors }">
                <label for="brand_id" class="bmd-label-floating">Description</label>
                <textarea class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
                <app-datalist
                  label="Marque"
                  (selected)="onSelectedBrand($event)"
                  [items]="enumsService.enums.brands"
                  [preselect]="preselectBrand"
                  [property]="'name'">
                </app-datalist>
              </p>
            </div>

            <label style="color:#000">Photo</label>
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <app-drag-drop-file-input
                [limit]="1"
                appDragDrop
                [forceTypes]="['webp', 'jpg', 'jpeg', 'png']"
                [preview]="true"
                [list]="editMode.active && editMode.data.tire_model_photo ? editMode.data.tire_model_photo : null"
                (filesSelected)="onFileSelected($event)"
                (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
              <small>Remarques : images de type .webp, .jpg, .jpeg, .png / Aucune limite de dimensions / Poids max de 3,0 Mo</small>
              <br>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.video_key.errors }">
                <label for="video_key" class="bmd-label-floating">Clé de vidéo Youtube</label>
                <input type="text" class="form-control" id="video_key" formControlName="video_key">
              </p>
            </div>

            <!--FIELD CHECKBOX-->
            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="brand_highlight" formControlName="brand_highlight" [value]="true">
                  Profil à mettre en avant sur la page Marque
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
            <!--END FIELD CHECKBOX-->

            <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </form>
        </div>

      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
