import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Audit } from 'src/app/models/Audit';

import { AlertService } from 'src/app/services/alert.service';
import { HistoryService } from 'src/app/services/history.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [PoliciesService]
})
export class ListComponent implements OnInit {
  translations: any = {};
  public items: Array<Audit> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: {}[] = [{
    name: 'Quoi',
    slug: 'auditable_type',
    selected_emit_property: 'value',
    property: "name",
    type: 'select',
    class: 'col-md-4',
    value: null,
    options: [
      {name: "Agence", value: "Agency"},
      {name: "Service d'une agence", value: "AgencyService"},
      {name: "Date de fermeture", value: "ClosedDate"},
      {name: "Horaire d'ouverture magasin", value: "OpeningTime"},
      {name: "Correspondant", value: "Correspondant"},
      {name: "Adresse", value: "Address"},
      {name: "Service", value: "Service"},
    ]
  },{
    name: 'ID',
    slug: 'auditable_id',
    type: 'integer',
    class: 'col-md-1',
    value: null
  },{
    name: 'Action',
    slug: 'action',
    selected_emit_property: 'slug',
    property: "name",
    type: 'select',
    class: 'col-md-4',
    options: [
      {slug: 'create', name: 'Création'},
      {slug: 'update', name: 'Modification'},
      {slug: 'destroy', name: 'Suppression'},
    ]
  },]
  public objectKeys = Object.keys;

  constructor(
    private cd: ChangeDetectorRef,
    private historyService: HistoryService,
    public enumsService: EnumsService,
    private alertService: AlertService,
    private http: HttpClient) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
        }
      });
    }

  ngOnInit(): void {
    this.loadTranslations();
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.historyService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  protected readonly Array = Array;

  loadTranslations() {
    this.http.get('/assets/i18n/fr.json').subscribe(data => {
      this.translations = data;
    });
  }

  getTranslation(key: string): string {
    return this.translations[key] || key;
  }
}
