import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-drag-drop-file-input',
  templateUrl: './drag-drop-file-input.component.html',
  styleUrls: ['./drag-drop-file-input.component.scss']
})
export class DragDropFileInputComponent implements OnInit {
  public files: Array<File> = [];
  public previews: any = {};

  @Output() filesSelected = new EventEmitter();
  @Output() fileDeleted = new EventEmitter();
  @Output() fileDownloaded = new EventEmitter();
  @Input() forceTypes: Array<string> = [];
  @Input() preview: boolean = false;
  @Input() downloadByLink: boolean = false;
  @Input() limit = null;
  @Input() list: Array<{ link: string, name: string }>;
  @Input() removeDeleteAction: boolean = false;

  constructor(private alertService: AlertService,
    private apiService: ApiService) { }

  ngOnInit(): void {
  }

  getType(file: File) {
    const ext = file.name.split('.').pop();
    if (ext === 'webp' || ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'gif') {
      return 'image';
    } else {
      return 'other';
    }
  }

  uploadFile(event) {
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (this.forceTypes) {
        const ext = element.name.split('.')[element.name.split('.').length - 1];
        if (this.forceTypes.includes(ext)) {
          this.files.push(element);
        } else {
          this.alertService.error('Le fichier ' + element.name + ' ne respecte pas le format demandé.');
        }
      } else {
        this.files.push(element);
      }
    }
    this.filesSelected.emit(this.files);
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.fileDeleted.emit(this.files);
  }

  deleteListedFile(index) {
    this.fileDeleted.emit(index);
  }

  dowloadAttachment(index) {
    this.apiService.getBlob(this.list[index].link)
      .then((blob: Blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = href;
        a.download = this.list[index].name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();


        this.fileDownloaded.emit(this.list);
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }


}
