import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { Page } from '../models/Page';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import parseFiltersForApiUrl from '../helpers/parseFiltersForApiUrl';

@Injectable({ providedIn: 'root' })
export class PagesService {
  constructor(private apiService: ApiService) {}

  getById(pageId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('pages/' + pageId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll(website: number = 0) {
    return new Promise((resolve, reject) => {
      this.apiService.get('pages/all' + `?website=${website}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(website: number = 0, page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('pages?page=' + page
        + `&website=${website}`
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForApiUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async formatData(page: Page) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('page:parent_menu', page.parent_menu ? page.parent_menu.toString() : '0');
      formData.append('page:rank', page.rank ? page.rank.toString() : '0');
      formData.append('page:title', page.title ? page.title : '');
      formData.append('page:meta_title', page.meta_title ? page.meta_title : '');
      formData.append('page:meta_description', page.meta_description ? page.meta_description : '');
      formData.append('page:slug', page.slug ? page.slug : '');
      formData.append('page:slug_key', page.slug_key ? page.slug_key : '');
      formData.append('page:content', page.content ? page.content : '');
      formData.append('page:url', page.url ? page.url : '');
      formData.append('page:emails_notifications', page.emails_notifications ? page.emails_notifications : '');
      formData.append('page:published', page.published ? page.published.toString() : 'false');
      formData.append('page:universe', page.universe ? page.universe.toString() : '0');
      formData.append('page:website', page.website ? page.website.toString() : '0');
      formData.append('page:start_at', page.start_at ? page.start_at.toString() : '');
      formData.append('page:end_at', page.end_at ? page.end_at.toString() : '');
      formData.append('page:noindex', page.noindex ? page.noindex.toString() : 'false');

      if (page.service) {
        formData.append('page:service_id', page.service.toString());
      }

      if (page.page_nav_photo !== null) {
        formData.append('page:page_nav_photo', page.page_nav_photo ? page.page_nav_photo : '');
      }
      if (page.background_image !== null) {
        formData.append('page:background_image', page.background_image ? page.background_image : '');
      }
      if (page.background_image_right !== null) {
        formData.append('page:background_image_right', page.background_image_right ? page.background_image_right : '');
      }
      resolve(formData);
    });
  }

  edit(page) {
    return new Promise((resolve, reject) => {
      this.formatData(page).then((formData) => {
        if (page.id) {
          this.apiService.put('pages/' + page.id, formData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
        } else {
          this.apiService.post('pages', formData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  delete(pageId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('pages/' + pageId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
