<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des agences ouvertes</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-9">
    <a *ngIf="policiesService.currentUser.policies['open-agency'].create" class="ppp-button ppp-button-black btn btn-primary btn-round"
    [routerLink]="['/profilplus-agences/open-agencies/edit']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle agence
    </a>
    <div>
      <button *ngIf="policiesService.currentUser.is_admin || (policiesService.currentUser && policiesService.currentUser.adherents.length > 0)" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadAgenciesMultiWorksheet()">
        <i class="material-icons">pie_chart</i>
        Export Agences ouvertes (multi)
      </button>
      <button *ngIf="policiesService.currentUser.is_admin || (policiesService.currentUser && policiesService.currentUser.adherents.length > 0)" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadOnlineAgenciesMonoWorksheet()">
        <i class="material-icons">pie_chart</i>
        Export Agences ouvertes (mono)
      </button>
      <button *ngIf="policiesService.currentUser.is_admin"
        class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadPLV()">
        <i class="material-icons">pie_chart</i>
        Export PLV
      </button>
      <button *ngIf="policiesService.currentUser.is_admin"
        class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadADV()">
        <i class="material-icons">pie_chart</i>
        Export ADV
      </button>
      <button *ngIf="policiesService.currentUser.is_admin"
        class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadEMA()">
        <i class="material-icons">pie_chart</i>
        Export EMA
      </button>
    </div>
  </div>

  <div  class="col-md-3 text-right">
    <app-file-input #FileInput *ngIf="(policiesService.currentUser.is_admin || (policiesService.currentUser && policiesService.currentUser.adherents.length > 0)) && !fileImporting; else templateImporting"
      btnClass="ppp-button ppp-button-black btn btn-primary btn-round"
      (filesSelected)="importFile($event)" label="Import de masse"></app-file-input>
    <ng-template #templateImporting>
      <span *ngIf="policiesService.currentUser.is_admin || (policiesService.currentUser && policiesService.currentUser.adherents.length > 0)" class="ppp-button ppp-button-black btn btn-primary btn-round" disabled>Import en cours...</span>
    </ng-template>
    <a [routerLink]="['/profilplus-agences/help']" target="_blank" class="nav-link navbar-text ppp-help">
      <i class="material-icons">help_outline</i>
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des agences ouvertes
        </h4>
      </div>
      <div class="card-body">

        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="50">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="240">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Société" prop="company" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a title="Voir la Société" class="ppp-link" target="_blank" [routerLink]="['/adherents-companies-management/company/edit/', row.company.id]" *ngIf="row.company
                && policiesService.currentUser.policies.company && policiesService.currentUser.policies.company.update; else companyOnlyData">
                  {{ (row.company) ? row.company.name : ''}}
                </a>
                <ng-template #companyOnlyData>
                  {{ (row.company) ? row.company.name : ''}}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Adhérent" prop="adherent" [width]="200">
              <ng-template let-row="row"  let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'adhérent" class="ppp-link" target="_blank" [routerLink]="['/adherents-companies-management', (value.is_closed ? 'closed' : 'open'), 'edit', value.id]"
                *ngIf="row.adherent && policiesService.currentUser.policies.adherent && policiesService.currentUser.policies.adherent.update; else adherentOnlyData">
                  {{ (row.adherent) ? row.adherent.name : '' }}
                </a>
                <ng-template #adherentOnlyData>
                  {{ (row.adherent) ? row.adherent.name : '' }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Nom du chef d'agence" prop="lastname_manager" [width]="210">
              <ng-template let-row="row" let-value="value"  ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="300" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les informations de l'agence">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/skills/', row.id]" type="button" class="btn btn-success" title="Modifier les métiers">
                  <i class="material-icons">build</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/services/', row.id]" type="button" class="btn btn-success" title="Modifier les services">
                  <i class="material-icons">playlist_add_check</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/close-dates/', row.id]" type="button" class="btn btn-success" title="Modifier les dates de fermeture">
                  <i class="material-icons">report</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/opening-times/store/', row.id]" type="button" class="btn btn-success" title="Modifier les horaires d'ouverture magasin">
                  <i class="material-icons">store</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['open-agency'].update"
                  [routerLink]="['/profilplus-agences/open-agencies/correspondants/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les correspondants">
                  <i class="material-icons">people</i>
                </a>
                <a *ngIf="policiesService.currentUser.is_admin"
                  [routerLink]="['/profilplus-agences/open-agencies/edit/settings/', row.id]" type="button" class="btn btn-success" title="Paramètres avancés">
                  <i class="material-icons">settings</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
