import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { HistoryComponent } from './history.component';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

import { ListComponent } from './list/list.component';

import { ShowComponent } from './show/show.component';

const routes: Routes = [
    {
        path: 'history',
        component: HistoryComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'list/page/:page',
            component: ListComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'list/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HistoryRoutingModule {}
