import { Component, OnInit } from '@angular/core';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';

import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { WorkersService } from 'src/app/services/workers.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-file-mapping',
  templateUrl: './file-mapping.component.html',
  styleUrls: ['./file-mapping.component.scss']
})
export class FileMappingComponent implements OnInit {

  public modules;
  public modulesStringList;
  public user: User;

  public loading: boolean = false;

  public fileMappings = [];

  constructor(
    public policiesService: PoliciesService,
    private workersService: WorkersService,
    private authService: AuthService,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private apiService: ApiService) {

    this.modules = modules;
    this.policiesService.currentUser = this.authService.currentUserValue;

    this.loading = true;

  }

  ngOnInit(): void {
    this.enumsService.observable.subscribe({
      complete: () => {
        this.workersService.getNotifications('files_mapping').then((response: []) => {
          this.fileMappings = response.filter((worker: any) => worker.request_type === 'files_mapping');
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      }
    });
  }

  clickDownload(url, filename) {
    this.apiService.getBlob(url)
      .then((blob: Blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = href;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => {
        this.alertService.error(err);
      })
  }

}
