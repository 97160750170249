<aside class="jumbotron filter-bar ">
  <form #form="ngForm" class="container row">
    <ng-container *ngFor="let filter of filters">
      <div [ngSwitch]="filter.type" class="col" [ngClass]="filter.class">

        <ng-container *ngSwitchCase="'radio'">
          <div class="form-group form-radio-group">
            <label class="col-form-label">{{filter.name}}</label>
            <div class="form-check" *ngFor="let option of filter.options">
              <label class="form-check-label">
                <input (click)="onChanged(filter.slug, option.value)" class="form-check-input" type="radio" [name]="filter.slug" [value]="option.value" [checked]="option.default"> {{option.name}}
                <span class="circle">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'autocomplete'">
          <div>
            <app-datalist
              (typed)="filter.on_select_only ? null : onChanged(filter.slug, $event, true)"
              (selected)="onChanged(filter.slug, (filter.selected_emit_property) ? $event[filter.selected_emit_property] : $event.name)"
              [label]="filter.name"
              [items]="filter.options"
              [property]="filter.property ? filter.property : 'name'"
            ></app-datalist>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
          <div  class="form-group">
            <label  class="col-form-label" [for]="filter.slug">{{filter.name}}</label>
            <select class="selectpicker-vanilla" [name]="filter.slug" id="" (change)="onChanged(filter.slug, $event.target.value)">
              <option [value]="''" default>Selectionner</option>
              <option
                [value]="filter.selected_emit_property ? item[filter.selected_emit_property] : item"
                *ngFor="let item of filter.options">
                {{filter.property ? item[filter.property] : item}}
              </option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input"
              type="checkbox" class="form-check-input"
              (input)="onChanged(filter.slug, $event.target.checked, true)"
              [name]="filter.name"> {{filter.name}}
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'year'">
          <div class="form-group">
            <label class="col-form-label">{{filter.name}}</label>
            <div class="form-group">
              <input type="text" minlength="4" maxlength="4" NumbersOnly [allowDecimals]="false" [allowSign]="false"
              (input)="onChanged(filter.slug, $event.target.value, true)" class="form-control" [name]="filter.name" [value]="filter.value">
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'integer'">
          <div class="form-group">
            <label class="col-form-label">{{filter.name}}</label>
            <div class="form-group">
              <input type="number" (input)="onChanged(filter.slug, $event.target.value, true)" class="form-control" [name]="filter.name" [value]="filter.value">
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="form-group">
            <label class="col-form-label">{{filter.name}}</label>
            <div class="form-group">
              <input [type]="filter.type" (input)="onChanged(filter.slug, $event.target.value, true)" class="form-control" [name]="filter.name" [value]="filter.value">
            </div>
          </div>
        </ng-container>

      </div>
    </ng-container>
    <div class="col-12">
      <ng-content></ng-content>
    </div>
  </form>
</aside>
