import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { Audit } from 'src/app/models/Audit';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { HistoryService } from 'src/app/services/history.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  translations: any = {};
  public modules;
  public modulesStringList;
  public user: User;
  public objectKeys = Object.keys;

  currentModule: string = null;
  currentService: { name: string, slug: string, sub: any } = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: Array<Audit>;
  public fields: Array<any>;

  constructor(private authService: AuthService,
    public historyService: HistoryService,
    public alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient) {

    this.currentModule = this.router.url.split('/')[1];
    this.modules = modules;

    this.currentService.slug = this.router.url.split('/')[2];
    switch (this.currentService.slug) {
      case 'list':
        this.currentService.name = 'historiques';
        this.fields = [
          { name: 'ID', slug: 'id' },
          { name: 'Action', slug: 'action', template: true },
          { name: 'Qui', slug: 'username', template: true },
          { name: 'Date', slug: 'created_at', template: true },
          { name: 'Modifications', slug: 'handle_audited_changes', template: true },
        ];
        this.historyService.getById(this.route.snapshot.params.id)
          .then((response: Array<Audit>) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.loadTranslations();
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

  protected readonly Array = Array;

  loadTranslations() {
    this.http.get('/assets/i18n/fr.json').subscribe(data => {
      this.translations = data;
    });
  }

  getTranslation(key: string): string {
    return this.translations[key] || key;
  }
}
