import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { ServicesService } from 'src/app/services/services.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';

@Component({
  selector: 'app-edit-services',
  templateUrl: './edit-services.component.html',
  styleUrls: ['./edit-services.component.scss']
})
export class EditServicesComponent implements OnInit {
  public services: Array<any> = [];
  public agency: Agency;
  public agencyServices: Array<{ id: number, data: string | number }> = [];
  public agencySkills: Array<any> = [];
  public loading: boolean = false;

  public vlServices: Array<any> = [];
  public plServices: Array<any> = [];
  public agServices: Array<any> = [];
  public maServices: Array<any> = [];
  public gcServices: Array<any> = [];
  public motoServices: Array<any> = [];
  public tpServices: Array<any> = [];
  public plusServices: Array<any> = [];
  private listAstreinte: Array<any> = [];

  public corresponds = {
    "vl": {
      "remplacement-de-pneus": [
        { slug: 'montage', disabled: true },
        { slug: 'reparation-de-pneus-tubeless', disabled: true },
        { slug: 'controle-des-pressions-pneus-offert', disabled: false }
      ],
      "vidange": [
        { slug: 'diagnostic-visuel-offert', disabled: true },
        { slug: 'batterie', disabled: false },
        { slug: 'essuie-glace', disabled: false }
      ]
    },
    "gc": {
      "vente-de-pneus-genie-civil": [
        { slug: 'reparation-a-froid-et-a-chaud', disabled: true },
        { slug: 'gonflage-a-la-mousse-de-polyurethane', disabled: true }
      ]
    },
    "ma": {
      "vente-de-pneus-manutention": [
        { slug: 'gonflage-mousse', disabled: true },
        { slug: 'gestion-dechet', disabled: true }
      ]
    },
    "tp": {
      "vente-tp": [
        { slug: 'gonflage-mousse', disabled: true }
      ]
    }
  }

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    private servicesService: ServicesService,
    public loadingService: LoadingService,
    public policiesService: PoliciesService,
    private authService: AuthService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.id));
        }

        this.agenciesService.getSkills(this.route.snapshot.params.id).then((response: Array<any>) => {
          this.agencySkills = response ? response.map(x => x.id) : [];
          this.loading = false;
        }).catch((err) => {
          this.alertService.error(err);
          this.loading = false;
        });

        this.services = this.enumsService.enums.services;
        this.services.forEach(s => {
          switch (s.skill.name) {
            case 'Agraire': {
              this.agServices.push(s);
              break;
            }
            case 'Auto, Camionnette, 4X4': {
              this.vlServices.push(s);
              break;
            }
            case 'Génie civil': {
              this.gcServices.push(s);
              break;
            }
            case 'Manutention': {
              this.maServices.push(s);
              break;
            }
            case 'Moto': {
              this.motoServices.push(s);
              break;
            }
            case 'Poids lourd': {
              this.plServices.push(s);
              break;
            }
            case 'Travaux publics': {
              this.tpServices.push(s);
              break;
            }
            case 'Nos services Plus': {
              this.plusServices.push(s);
              break;
            }
            default: {
              break;
            }
          }
        });

        // Load preselect data
        this.agenciesService.getServices(this.route.snapshot.params.id).then((response: Array<any>) => {
          this.agencyServices = response.map((x) => {
            return { id: x.service_id, data: x.custom_data };
          });
          this.loading = false;
        }).catch((err) => {
          this.alertService.error(err);
          this.loading = false;
        });
      }
    });
  }

  hasThisSkill(skillId) {
    return this.agencySkills.includes(skillId) ? true : false;
  }

  fromAgencyServices(id) {
    const index = this.agencyServices.findIndex(s => s.id === id);
    if (index !== -1) {
      return this.agencyServices[index];
    }
    return null;
  }

  searchBySlug(slug) {
    return this.services.find(s => s.slug === slug);
  }

  getArrayBySkill(serviceSkill) {
    let array: Array<any> = [];
    switch (serviceSkill) {
      case 'vl':
        array = this.vlServices;
        break;
      case 'gc':
        array = this.gcServices;
        break;
      case 'moto':
        array = this.motoServices;
        break;
      case 'pl':
        array = this.plServices;
        break;
      case 'tp':
        array = this.tpServices;
        break;
      case 'ag':
        array = this.agServices
        break;
      case 'ma':
        array = this.maServices;
        break;
      case 'plus':
        array = this.plusServices;
        break;
      default:
        break;
    }
    return array;
  }

  onSelectService(e, serviceSkill, title = "") {
    // GET FULL DATA ABOUT selected service
    let array = this.getArrayBySkill(serviceSkill);

    const index = array.findIndex(s => s.id === parseInt(e.target.value));

    // CHECK / UNCHECK
    if (e.target.checked) {
      this.agencyServices.push({ id: parseInt(e.target.value), data: null });

      if (this.corresponds[serviceSkill]) {
        // auto check somme neasty searvices
        if (index != -1) {
          if (this.corresponds[serviceSkill][array[index].slug]) {
            this.corresponds[serviceSkill][array[index].slug]
              .forEach(correspond => {
                // Add to agencyServices every items in this.corresponds[serviceSkill][array[index].slug]
                if (array.findIndex(s => s.slug === correspond.slug) !== -1) {
                  this.agencyServices.push({
                    id: array[array.findIndex(s => s.slug === correspond.slug)].id,
                    data: null
                  })
                }
              });
          }
        }

      }

      if (title.includes("streinte")) {
        this.listAstreinte.push({
          id: e.target.value,
          title: title
        })
      }
    } else {
      let indexFromAgencyServices = this.agencyServices.findIndex(a => a.id === parseInt(e.target.value));
      if (indexFromAgencyServices !== -1) {
        this.agencyServices.splice(indexFromAgencyServices, 1).slice(0);
        // auto remove disabled corresponds
        if (this.corresponds[serviceSkill] !== undefined && this.corresponds[serviceSkill] &&
          this.corresponds[serviceSkill][array[index].slug]) {

          this.corresponds[serviceSkill][array[index].slug]
            .forEach(correspond => {
              let indexFromarray = array.findIndex(s => s.slug === correspond.slug);
              indexFromAgencyServices = this.agencyServices.findIndex(a => a.id === array[indexFromarray].id);
              if (indexFromarray !== -1 && indexFromAgencyServices !== -1) {
                this.agencyServices.splice(indexFromAgencyServices, 1).slice(0);
              }
            });
        }
      }

      if (title.includes("streinte")) {
        const index = this.listAstreinte.indexOf({
          id: e.target.value,
          title: title
        })
        this.listAstreinte.splice(index, 1)
      }

    }
  }

  isDisabled(slug, serviceSkill): boolean {
    let array: Array<any> = this.getArrayBySkill(serviceSkill);
    let parent = null;
    let child = null;
    if (this.corresponds[serviceSkill]) {
      for (const [key, value] of Object.entries(this.corresponds[serviceSkill]) as [string, any]) {
        if (value.find(e => e.slug === slug)) {
          parent = key;
          child = value.find(e => e.slug === slug);
        }
      }
    }

    return array.find(s => s.slug === parent)
      && this.agencyServices.find(as => as.id === array.find(s => s.slug === parent).id)
      && child && child.disabled;
  }

  updateServiceInfo(id, $event) {
    let index = this.agencyServices.findIndex(s => s.id === id)
    if (index !== -1) {
      this.agencyServices[index].data = $event.target.value
    }
  }

  onSubmit() {
    try {
      this.allAstreinteDataFilled()

      this.agenciesService.updateServices(this.route.snapshot.params.id, this.agencyServices)
        .then(() => {
          this.alertService.success('Services mis à jour.');
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
    catch (err) {
      this.alertService.error(err)
    }
  }

  allAstreinteDataFilled() {
    if (this.listAstreinte.length === 0) {
      return true
    }

    this.listAstreinte.forEach((astreinte) => {
      let element = this.agencyServices.find((service) => {
        return service.id == parseInt(astreinte.id)
      })
      if (element.data == null || element.data == "") {
        throw `Veuillez svp remplir les données complémentaires du champs ${astreinte.title}`
      }
    })
  }
}
