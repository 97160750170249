<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="commercial-actions">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/commercial-actions']">Actions commerciales</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item active"><a href="javascript:;">Édition d'une action commerciale {{editFormGroup.value.adherent_id ? 'locale' : 'nationale' }}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une nouvelle action commerciale {{ user.is_admin ? 'nationale' : 'locale' }}</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup">
    <div class="ppp-commercialActions row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">

          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">local_offer</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition de l'action commerciale <strong class="description">{{editFormGroup.value.adherent_id ? 'locale' : 'nationale' }}</strong> : {{editMode.data.name}}</h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'une nouvelle action commerciale <strong class="description">{{ user.is_admin ? 'nationale' : 'locale' }}</strong></h4>
              </ng-template>
            </div>

            <div class="card-body">
              <h1>Informations générales</h1>

              <!-- COMMERCIAL ACTION NAME -->
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required"  [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                  <label for="name" class="bmd-label-floating">Titre de l'opération commerciale</label>
                  <input type="text" class="form-control" id="name" formControlName="name">
                </p>
              </div>

              <div *ngIf="!editMode.active" class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.action_type.errors }">
                  <label class="col-form-label">Type d'action commerciale :</label>
                  <div class="form-group">
                    <select name="action_type" formControlName="action_type" class="ppp-commercial-action-select">
                      <option [ngValue]="0">Standard</option>
                      <option [ngValue]="1">Avec utilisation d'un fichier de codes promotionnels</option>
                      <option [ngValue]="2">Avec génération et saisie de codes promotionnels</option>
                      <option [ngValue]="3">Avec génération d'un code, envoi SMS et saisie du code</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-check" *ngIf="user.is_admin">
                <label class="form-check-label" style="color:#000;">
                  <input class="form-check-input" type="checkbox" name="automatic_emails" formControlName="automatic_emails">
                  Envoi automatique des statistiques par emails
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                  <a [routerLink]="['/commercial-actions/help']" target="_blank" class="nav-link navbar-text ppp-help">
                    <i class="material-icons">help_outline</i>
                  </a>
                </label>
              </div>

              <!-- "ADHÉRENT CONCERNÉ" -->
              <!-- Pour les AC "Standard" (0) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
              <ng-container *ngIf="(editFormGroup.value.action_type === 0 || editFormGroup.value.action_type === 2 || editFormGroup.value.action_type === 3)">
                <div *ngIf="user.adherents && user.adherents.length > 1; else templateElseAdherent" class="col-lg-4 col-md-12" style="padding:0;">
                  <app-datalist label="Adhérent concerné" [items]="adherentsList" [property]="'name'" [preselect]="preselectAdherent" (selected)="onSelectAdherent($event)"></app-datalist>
                  <br>
                </div>
                <ng-template #templateElseAdherent>
                  <p *ngIf="editFormGroup.value.adherent_id"><br>{{adherentsList[preselectAdherent].name}}</p>
                </ng-template>
              </ng-container>

              <!-- "ACTION COMMERCIALE LIÉE À (univers)" -->
              <div class="col-lg-4 col-md-12" style="padding:0;" *ngIf="(editFormGroup.value.action_type === 0 || editFormGroup.value.action_type === 2 || editFormGroup.value.action_type === 3 )">
                <div
                  style="margin-bottom:20px;"
                  class="form-group form-radio-group is-required"
                  [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                  <label class="col-form-label">Action commerciale liée à :</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="0"> B2C VL
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="1"> B2B VL
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="2"> B2B PL
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="3"> B2B AG
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <!-- "DATE DE DÉBUT" -->
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
                  <label class="col-form-label">Date de début</label>
                  <div class="form-group">
                    <input type="date" class="form-control datetimepicker" name="start_at" formControlName="start_at">
                  </div>
                </div>
              </div>

              <!-- "DATE DE FIN" -->
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.end_at.errors }">
                  <label class="col-form-label">Date de fin</label>
                  <div class="form-group">
                    <input type="date" class="form-control datetimepicker" name="end_at" formControlName="end_at">
                    <small>Remarque : Pour une action commerciale se terminant à  « date » indiquer « date + 1 jour »</small>
                  </div>
                </div>
              </div>

              <!-- "DATE LIMITE DE VALIDITÉ DES BONS" -->
              <div class="col-lg-4 col-md-12 mb-4" style="padding:0;" *ngIf="editFormGroup.value.action_type === 3">
                <div class="form-group is-required"  [ngClass]="{ 'has-danger': submitted && f.validity_deadline_date.errors }">
                  <label for="name" class="bmd-label-floating">Date limite de validité</label>
                  <input type="date" class="form-control" id="validity_deadline_date" formControlName="validity_deadline_date">
                </div>
              </div>

              <!-- "BUDGET" -->
              <!-- Pour les AC "Standard" (0) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
              <div class="col-lg-4 col-md-12" style="padding:0;" *ngIf="editFormGroup.value.action_type === 0 || editFormGroup.value.action_type === 2 || editFormGroup.value.action_type === 3">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.budget.errors }">
                  <label for="budget" class="bmd-label-floating">
                    <ng-container *ngIf="(editMode.active && editMode.data); else templateBudgetCreate">
                      Budget {{editFormGroup.value.adherent_id ? 'local' : 'national' }}
                    </ng-container>
                    <ng-template #templateBudgetCreate>Budget {{ user.is_admin ? 'national' : 'local' }}</ng-template>
                    {{(editFormGroup.value.universe > 0) ? 'HT' : 'TTC'}}
                  </label>
                  <input type="number" class="form-control" id="budget" formControlName="budget" min="0" NumbersOnly [allowDecimals]="true" [allowSign]="true">
                  <br>
                </p>
              </div>

              <!-- "NOMBRE D'ACTIONS IMMÉDIATES" -->
              <!-- Pour les AC "Standard" (0) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
              <div class="col-lg-4 col-md-12" style="padding:0;" *ngIf="editFormGroup.value.action_type === 0 || editFormGroup.value.action_type === 2 || editFormGroup.value.action_type === 3">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.immediate_actions_number.errors }">
                  <label for="actions" class="bmd-label-floating">Nombre d'actions immédiates</label>
                  <input type="number" min="0" max="1000000" class="form-control" name="immediate_actions_number" id="actions" formControlName="immediate_actions_number" NumbersOnly [allowDecimals]="true" [allowSign]="true">
                    <br>
                </p>
              </div>

              <!-- "FICHIER DE CODES PROMOTIONNELS À ASSOCIER À CETTE ACTION COMMERCIALE" -->
              <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) -->
              <div *ngIf="editFormGroup.value.action_type === 1">
                <label style="color:#000">Fichier de codes promotionnels à associer à cette action commerciale*</label>
                <div class="col-lg-4 col-md-12 form-group is-required" style="padding:0;" [ngClass]="{ 'has-danger': submitted && f.data_file.errors }">
                  <app-drag-drop-file-input [limit]="1" [forceTypes]="['csv']" appDragDrop [list]="editMode.active && editMode.data.data_file ? editMode.data.data_file : null" (filesSelected)="onPromoCodeFileSelected($event)" (fileDeleted)="onPromoCodeFileSelected(null)"></app-drag-drop-file-input>
                  <small>Remarque : Fichier de type .CSV seulement. Si fichier Excel, veuillez l'exporter vers un format .CSV</small>
                  <br>
                </div>
              </div>

              <!-- "IMAGE ASSOCIÉE À CETTE ACTION COMMERCIALE" -->
              <label style="color:#000">Image associée à cette action commerciale</label>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <app-drag-drop-file-input [limit]="1" [forceTypes]="['webp', 'jpg', 'jpeg', 'png']" appDragDrop [preview]="true" [list]="editMode.active && editMode.data.banner ? editMode.data.banner : null" (filesSelected)="onFileSelected($event)" (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
                <small>Remarques : L'image doit être de format .webp, .jpg, .jpeg ou .png / Aucune limite de dimensions / Poids max de 3,0 Mo</small>
                <br>
              </div>

              <!-- "UTILISATEURS POUVANT CONTINUER LA SAISIE D'ACCORDS UNE FOIS L'ACTION COMMERCIALE TERMINÉE -->
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
                  <label class="col-form-label">Utilisateurs pouvant continuer la saisie d'accords une fois l'action commerciale terminée</label>
                  <div class="form-group" *ngIf="users">
                    <select multiple formControlName="users">
                      <option *ngFor="let user of users" [value]="user.id">{{user.email}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- "RÈGLES DE CALCUL DE LA REMISE" -->
              <!-- Pour les AC "Standard" (0) -->
              <div class="col-lg-4 col-md-12" style="padding:0;" *ngIf="editFormGroup.value.action_type === 0">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.rules.errors }">
                  <label for="rules" class="bmd-label-floating">
                    Règles de calcul de la remise
                    <a [routerLink]="['/commercial-actions/help']" target="_blank" class="nav-link navbar-text ppp-help">
                      <i class="material-icons">help_outline</i>
                    </a>
                  </label>
                  <input type="text" class="form-control" id="rules" formControlName="rules" (blur)="purify('rules', $event)">
                </p>
                <small>Remarque : indiquer les montants directement en HT s'il s'agit d'une action commerciale B2B.</small>
              </div>

            </div>
          </div>

          <!-- "BUDGET ADDTIONNEL PAR ADHÉRENT" -->
          <!-- Pour les AC "Standard" (0) -->
          <!-- Displayed only if create and admin (national) or edit with no adherent_id (national AC editing, not a local AC editing) -->
          <div class="card" *ngIf="user.is_admin && editFormGroup.value.action_type === 0 && (!editMode.active || (editMode.active && editMode.data && editFormGroup.value.adherent_id == null))">
            <div class="card-body">
              <h1>Budget additionnel par adhérent</h1>
              <div class="ppp-budget-dispatcher"
                *ngIf="editFormGroup.value.budget && editFormGroup.value.budget > 0 && ( editFormGroup.value.immediate_actions_number || editFormGroup.value.deferred_actions_number ); else noBudgetBlock">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Adhérents</th>
                      <th>Montant</th>
                      <th *ngIf="false && editFormGroup.value.deferred_actions_number > 0">Nombre d'actions différées</th>
                      <th *ngIf="editFormGroup.value.immediate_actions_number > 0">Nombre d'actions immédiates</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of budgetDispatcherList; let i = index">
                      <td>{{item.adherent.name}}</td>
                      <!-- Budget -->
                      <td contenteditable (focusout)="onFocusOut(i, 'budget', $event)">{{item.budget}}</td>
                      <!-- Deferred actions number -->
                      <ng-container *ngIf="false && editFormGroup.value.deferred_actions_number > 0">
                        <td contenteditable (focusout)="onFocusOut(i, 'deferred_actions_number', $event)">{{item.deferred_actions_number}}</td>
                      </ng-container>
                      <!-- Immediate actions number -->
                      <ng-container *ngIf="editFormGroup.value.immediate_actions_number > 0">
                        <td contenteditable (focusout)="onFocusOut(i, 'immediate_actions_number', $event)">{{item.immediate_actions_number}}</td>
                      </ng-container>
                      <td>
                        <button type="button" class="btn btn-danger" (click)="onDeleteBudgetDispatch(item)">
                          Supprimer
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="adherentsList">
                      <td>
                        <select class="form-control" [(ngModel)]="newBudgetDispatch.adherent" [ngModelOptions]="{standalone: true}">
                          <option [value]="null">Choisir un adhérent</option>
                          <option *ngFor="let item of adherentsList" [value]="item.id" [disabled]="isIn(item)">{{item.name}}</option>
                        </select>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" min="0" name="budget" class="form-control" [(ngModel)]="newBudgetDispatch.budget" [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td *ngIf="false && editFormGroup.value.deferred_actions_number > 0">
                        <div class="form-group">
                          <input type="number" min="0" name="deferred_actions_number" class="form-control" [(ngModel)]="newBudgetDispatch.deferred_actions_number" [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td *ngIf="editFormGroup.value.immediate_actions_number > 0">
                        <div class="form-group">
                          <input type="number" min="0" name="immediate_actions_number" class="form-control" [(ngModel)]="newBudgetDispatch.immediate_actions_number" [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td>
                        <!-- TODO: Later, change this so that we take into account deffered_actions_number in [disabled] -->
                        <button type="button" (click)="onAddBudgetDispatch()" class="btn btn-success" [disabled]="newBudgetDispatch.budget < 1">Ajouter</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ng-template #noBudgetBlock>
                <p class="alert alert-warning mt-4">
                  <!-- TODO: display "budget national" for admins and "budget local" for adherents  -->
                  <span>Vous devez renseigner un <strong>budget national</strong> et un <strong>nombre d'actions immédiates <span *ngIf="false">et/ou différées</span></strong> afin de définir un budget additionnel par adhérent.</span>
                </p>
              </ng-template>
            </div>
          </div>

          <!-- "CHAMPS PERSONNALISÉS SPÉCIFIQUES À CETTE ACTION COMMERCIALE" -->
          <!-- Pour les AC "Standard" (0) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
          <div class="ppp-commercialActions-customFields card" *ngIf="[0, 2, 3].includes(editFormGroup.value.action_type)">
            <div class="card-body">
              <h1>Champs personnalisés spécifiques à cette action commerciale
                {{ editFormGroup.value.universe === 0 ? 'B2C' : 'B2B' }}
              </h1>
              <!-- CHAMPS B2C -->
              <ng-container *ngIf="editFormGroup.value.universe === 0">
                <div class="row" *ngIf="checkboxesGeneralFields && checkboxesUserFields">
                  <div class="col-lg-6">
                    <div class="ppp-commercialActions-customFields-left" [ngClass]="{ 'has-danger': submitted && f.commercial_action_user_fields.errors }">
                      <p>Sélection des champs d'information <strong>B2C</strong> qui seront affichés dans les demandes d'accord et qui seront demandés aux clients pour cette action commerciale :</p>
                      <ng-container  *ngFor="let checkbox of customCbUserB2c">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" (change)="onChangeUserFields($event)" name="contact_infos" [checked]="testCb(checkbox.slug, checkboxesUserFields) !== -1" [value]="checkbox.slug" > {{checkbox.label}}
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                        <div *ngIf="testCb(checkbox.slug, checkboxesUserFields) !== -1">
                          <label [for]="'required-' + checkbox.slug + '-1'" style="margin-right: 15px;">
                            <input type="radio" [checked]="this.checkboxesUserFields[testCb(checkbox.slug, checkboxesUserFields)].required" [name]="'required-' + checkbox.slug + '-1'" [id]="'required-' + checkbox.slug + '-1'" (click)="onChangeUserFieldRequired(testCb(checkbox.slug, checkboxesUserFields), true)"> Obligatoire
                          </label>
                          <label [for]="'required-' + checkbox.slug + '-2'">
                            <input type="radio" [checked]="!this.checkboxesUserFields[testCb(checkbox.slug, checkboxesUserFields)].required" [name]="'required-' + checkbox.slug + '-2'" [id]="'required-' + checkbox.slug + '-2'" (click)="onChangeUserFieldRequired(testCb(checkbox.slug, checkboxesUserFields), false)"> Facultatif
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="editFormGroup.value.action_type !== 2">
                    <div class="ppp-commercialActions-customFields-right" [ngClass]="{ 'has-danger': submitted && f.commercial_action_general_fields.errors }">
                      <p>Sélection des champs d'information <strong>B2C</strong> qui seront affichés dans les demandes d'accord et qui seront demandés au niveau des informations générales de cette action commerciale :</p>
                      <ng-container *ngFor="let checkbox of customCbGeneralB2c">
                        <ng-container *ngIf="editFormGroup.value.action_type != 3 || (editFormGroup.value.action_type == 3 && (checkbox.slug == 'tire_brand' || checkbox.slug == 'price_unit_ttc' || checkbox.slug == 'tire_count' || checkbox.slug == 'general_diameter'))">
                          <div class="form-check" *ngIf="(checkbox.type && checkbox.type.includes(f.universe.value)) || checkbox.type.length == 0">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" (change)="onChangeGeneralField($event)"
                              [disabled]=" (checkbox.slug === 'tire_brand' && testCb('tire_brand_b_g_d', checkboxesGeneralFields) !== -1) || (checkbox.slug === 'tire_brand_b_g_d' && testCb('tire_brand', checkboxesGeneralFields) !== -1)" name="contact_infos" [checked]="testCb(checkbox.slug, checkboxesGeneralFields) !== -1" [value]="checkbox.slug" > {{checkbox.label}}
                              <span class="form-check-sign">
                                <span class="check"></span>
                              </span>
                            </label>
                          </div>
                          <div *ngIf="testCb(checkbox.slug, checkboxesGeneralFields) !== -1">
                            <label [for]="'required-' + checkbox.slug + '-1'" style="margin-right: 15px;">
                              <input type="radio" [checked]="this.checkboxesGeneralFields[testCb(checkbox.slug, checkboxesGeneralFields)].required" [name]="'required-' + checkbox.slug + '-1'" [id]="'required-' + checkbox.slug + '-1'" (click)="onChangeGeneralFieldRequired(testCb(checkbox.slug, checkboxesGeneralFields), true)"> Obligatoire
                            </label>
                            <label [for]="'required-' + checkbox.slug + '-2'">
                              <input type="radio" [checked]="!this.checkboxesGeneralFields[testCb(checkbox.slug, checkboxesGeneralFields)].required" [name]="'required-' + checkbox.slug + '-2'" [id]="'required-' + checkbox.slug + '-2'" (click)="onChangeGeneralFieldRequired(testCb(checkbox.slug, checkboxesGeneralFields), false)"> Facultatif
                            </label>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- CHAMPS B2B -->
              <ng-container *ngIf="editFormGroup.value.universe > 0">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="ppp-commercialActions-customFields-left" [ngClass]="{ 'has-danger': submitted && f.commercial_action_user_fields.errors }">
                      <p>Sélection des champs d'information <strong>B2B</strong> qui seront affichés dans les demandes d'accord et qui seront demandés aux clients pour cette action commerciale :</p>
                      <ng-container *ngFor="let checkbox of customCbUserB2B">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" (change)="onChangeUserFields($event)"
                            name="contact_infos" [checked]="testCb(checkbox.slug, checkboxesUserFields) !== -1" [value]="checkbox.slug" > {{checkbox.label}}
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                        <div *ngIf="testCb(checkbox.slug, checkboxesUserFields) !== -1">
                          <label [for]="'required-' + checkbox.slug + '-1'" style="margin-right: 15px;">
                            <input type="radio" [checked]="this.checkboxesUserFields[testCb(checkbox.slug, checkboxesUserFields)].required" [name]="'required-' + checkbox.slug + '-1'" [id]="'required-' + checkbox.slug + '-1'" (click)="onChangeUserFieldRequired(testCb(checkbox.slug, checkboxesUserFields), true)"> Obligatoire
                          </label>
                          <label [for]="'required-' + checkbox.slug + '-2'">
                            <input type="radio" [checked]="!this.checkboxesUserFields[testCb(checkbox.slug, checkboxesUserFields)].required" [name]="'required-' + checkbox.slug + '-2'" [id]="'required-' + checkbox.slug + '-2'" (click)="onChangeUserFieldRequired(testCb(checkbox.slug, checkboxesUserFields), false)"> Facultatif
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="editFormGroup.value.action_type !== 2">
                    <div class="ppp-commercialActions-customFields-right" [ngClass]="{ 'has-danger': submitted && f.commercial_action_general_fields.errors }">
                      <p>Sélection des champs d'information <strong>B2B</strong> qui seront affichés dans les demandes d'accord et qui seront demandés au niveau des informations générales de cette action commerciale :</p>
                      <ng-container  *ngFor="let checkbox of customCbGeneralB2B">
                        <ng-container *ngIf="(editFormGroup.value.action_type != 3 && checkbox.slug != 'price_unit_ttc')
                          || (editFormGroup.value.action_type == 3 && (checkbox.slug == 'tire_brand' || checkbox.slug == 'price_unit_ttc' || checkbox.slug == 'tire_count'))">
                          <div class="form-check" *ngIf="checkbox.type && checkbox.type.includes(f.universe.value) || checkbox.type.length == 0">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" (change)="onChangeGeneralField($event)" [disabled]="(checkbox.slug === 'tire_brand' && testCb('tire_brand_b_g_d', checkboxesGeneralFields) !== -1) || (checkbox.slug === 'tire_brand_b_g_d' && testCb('tire_brand', checkboxesGeneralFields) !== -1)" name="contact_infos" [checked]="testCb(checkbox.slug, checkboxesGeneralFields) !== -1" [value]="checkbox.slug" > {{checkbox.label}}
                              <span class="form-check-sign">
                                <span class="check"></span>
                              </span>
                            </label>
                          </div>
                          <div *ngIf="testCb(checkbox.slug, checkboxesGeneralFields) !== -1">
                            <label [for]="'required-' + checkbox.slug + '-1'" style="margin-right: 15px;">
                              <input type="radio" [checked]="this.checkboxesGeneralFields[testCb(checkbox.slug, checkboxesGeneralFields)].required" [name]="'required-' + checkbox.slug + '-1'" [id]="'required-' + checkbox.slug + '-1'" (click)="onChangeGeneralFieldRequired(testCb(checkbox.slug, checkboxesGeneralFields), true)"> Obligatoire
                            </label>
                            <label [for]="'required-' + checkbox.slug + '-2'">
                              <input type="radio" [checked]="!this.checkboxesGeneralFields[testCb(checkbox.slug, checkboxesGeneralFields)].required" [name]="'required-' + checkbox.slug + '-2'" [id]="'required-' + checkbox.slug + '-2'" (click)="onChangeGeneralFieldRequired(testCb(checkbox.slug, checkboxesGeneralFields), false)"> Facultatif
                            </label>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- BOUTON DE CRÉATION OU DE MISE À JOUR -->
          <div>
            <button [disabled]="editFormGroup.invalid && !( (editFormGroup.value.immediate_actions_number
              || editFormGroup.value.deferred_actions_number) && (editFormGroup.value.action_type === 0 || editFormGroup.value.action_type === 2 || editFormGroup.value.action_type !== 3) )"  *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()"> ...</button>
            </ng-template>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
