<app-topbar [moduleSlug]="moduleSlug" [displayHelp]="false">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/', moduleSlug]">{{moduleNames[moduleSlug]}}</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Aide</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <!-- HEADER -->
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">help_outline</i>
        </div>
        <h4 class="card-title">Aide du module : <strong class="description">{{moduleNames[moduleSlug]}}</strong></h4>
      </div>

      <!-- MAIN CONTENT -->
      <div class="card-body">
        <div class="col-lg-12" style="padding:0;">

          <!-- UTILISATEURS -->
          <div *ngIf="moduleSlug == 'users-management'">
            <h2>Gestion des adhérents et des agences</h2>
            <p>
              Il est possible de consulter et éditer les adhérents et les agences d'un utilisateur seulement si ce dernier n'est pas un administrateur Profil Plus.<br>
              En effet, les administrateurs Profil Plus n'ont pas d'adhérents et d'agences rattachés, ils n'ont donc pas accès à cette partie du module.
            </p>

            <h2>Administrateur Profil Plus Direct</h2>
            <p>Cocher la case "Administrateur Profil Plus Direct" accorde à l'administrateur de réouvrir / déclôturer une intervention.</p>
          </div>

          <!-- ADHERENTS ET SOCIÉTÉS -->
          <div *ngIf="moduleSlug == 'adherents-companies-management'">
            <p>Aucune aide disponible pour le moment.</p>
          </div>

          <!-- ACTIONS COMMERCIALES -->
          <div *ngIf="moduleSlug == 'commercial-actions'">

            <h2>SECTIONS</h2>
            <ul>
              <li>[ACTION COMMERCIALE TYPE 1] Standard, avec règles de calcul de remise</li>
              <li>[ACTION COMMERCIALE TYPE 2] Avec utilisation d'un fichier de codes promotionnels</li>
              <li>[ACTION COMMERCIALE TYPE 3] Avec génération et saisie de codes promotionnels</li>
              <li>[ACTION COMMERCIALE TYPE 4] Avec génération d'un code, envoi SMS et saisie du code</li>
              <li>Sélection des champs spécifiques de l'action commerciale</li>
              <li>Envoi automatique des statistiques par emails</li>
            </ul>

            <hr>

            <h3>[ACTION COMMERCIALE TYPE 1] Standard (avec règles de calcul de remise)</h3>
            <p>
              Il faut sélectionner le type d'action commerciale <strong class="description">"Standard"</strong>.<br>
              Une action commerciale standard peut être définie en tant qu'action commerciale <strong class="description">nationale</strong>, ou <strong class="description">locale</strong>.
            </p>
            <p>
              Une action commerciale <strong class="description">nationale</strong> est accessible pour l'ensemble des adhérents du réseau Profil Plus.<br>
              une action commerciale <strong class="description">locale</strong> est accessible seulement pour l'adhérent qui l'a créée.
            </p>
            <p>
              Les administrateurs Profil Plus peuvent créer des actions commerciales nationales (et aussi définir des <strong class="description">budgets additionnels par adhérent</strong> sur ce type d'action commerciale).<br>
              Un adhérent peut créer ses propres agences commerciales locales. Ses agences pourront alors accéder à ces actions commerciales locales et créer des accords correspondants.
            </p>
            <p>
              Différentes règles de calcul de remise peuvent être définies sur une action commerciale Standard. Voir les points suivants.
            </p>

            <h4>Règles de calcul de remises sur le diamètre</h4>
            <p>
              S'il y a plusieurs diamètres concernés par la même action commerciale, il faut séparer les règles par des <code>points-virgules (;)</code><br>
              Voici quelques exemples de règles de calcul pour les remises :
            </p>
            <ul>
              <li>
                <div>Pour les <strong class="description">diamètres 15</strong>, remises de <strong class="description">7,5€</strong> :</div>
                <code>diameter-15_7.5</code>
              </li>
              <li>
                <div>Pour les <strong class="description">diamètres 16</strong>, remises de <strong class="description">10€</strong> :</div>
                <code>diameter-16_10</code>
              </li>
              <li>
                <div>Pour les <strong class="description">diamètres 17</strong>, remises de <strong class="description">17€</strong> :</div>
                <code>diameter-17_17</code>
              </li>
              <li>
                <div>Pour les <strong class="description">diamètres 15, 16 et 17</strong>, remises respectives de <strong class="description">7,5€, 10€ et de 17€</strong> :</div>
                <code>diameter-15_7.5;diameter-16_10;diameter-17_17</code>
              </li>
            </ul>
            <p>
              Remarque : indiquer les montants <strong class="description">directement en HT</strong> s'il s'agit d'une action commerciale B2B.<br>
              Remarque 2 : indiquer les remises avec décimales avec un point (.) en séparateur (ex: 7.5) et non pas avec une virgule (ex: 7,5).
            </p>

            <h4>Règles de calcul de remises sur les essieux</h4>
            <p>
              Il est possible de créer une action commerciale B2B basée sur les essieux.<br>
              S'il y a plusieurs essieux concernés par la même action commerciale B2B, il faut séparer les règles par des <code>points-virgules (;)</code><br>
              Voici quelques exemples de règles de calcul pour les remises :
            </p>
            <ul>
              <li>
                <div>Pour les <strong class="description">essieux T</strong>, remises de <strong class="description">15€ HT</strong> :</div>
                <code>axle-t_15</code>
              </li>
              <li>
                <div>Pour les <strong class="description">essieux S</strong>, remises de <strong class="description">25€ HT</strong> :</div>
                <code>axle-s_25</code>
              </li>
              <li>
                <div>Pour les <strong class="description">essieux D</strong>, remises de <strong class="description">25€ HT</strong> :</div>
                <code>axle-d_25</code>
              </li>
              <li>
                <div>Pour les <strong class="description">essieux T, S et D</strong>, remises respectives de <strong class="description">15€, 25€ et de 25€</strong> :</div>
                <code>axle-t_15;axle-s_25;axle-d_25</code>
              </li>
            </ul>
            <p>
              Remarque : indiquer les montants <strong class="description">directement en HT</strong> puisqu'il s'agit d'une action commerciale B2B.
            </p>

            <h4>Règles de calcul de remises sur les saisons, diamètres et nombre de pneus</h4>
            <p>
              Il est possible de créer une action commerciale avec des règles de remise basée sur les saisons, les diamètres et le nombre de pneus.<br>
              Dans ce cas-ci, il faut activer en obligatoire les champs "Nombre de pneus", "Diamètre de pneu" et "Saison".<br>
              La syntaxe des remises est la suivante :<br>
              <code>season-[summer OU winter OU allseasons]_diameter-[15 OU 16 OU ...]_tirecount-[2 OU 4]_[VALEUR DE LA REMISE]</code><br>
              Il faut absolument suivre cet ordre (season puis diameter puis tirecount)<br>
              Par exemple : <code>season-winter_diameter-18_tirecount-2_50</code> pour une remise de 50€ sur 2 pneus de diamètre 18 avec la saison hiver.<br>
              S'il y a plusieurs règles de ce type concernées, il faut les séparer par des <code>points-virgules (;)</code><br>
              Voici quelques exemples de règles de calcul pour ce type de remises :
            </p>
            <ul>
              <li>
                <div>Pour la saison <strong class="description">été</strong>, sur des diamètres de <strong class="description">15</strong> et pour un nombre de pneus de <strong class="description">4</strong>, avec une remise de <strong class="description">15€</strong> :</div>
                <code>season-summer_diameter-15_tirecount-4_15</code>
              </li>
              <li>
                <div>Pour la saison <strong class="description">hiver</strong>, sur des diamètres de <strong class="description">16</strong> et pour un nombre de pneus de <strong class="description">2</strong>, avec une remise de <strong class="description">35€</strong> :</div>
                <code>season-winter_diameter-16_tirecount-2_35</code>
              </li>
              <li>
                <div>Pour la saison <strong class="description">4 saisons</strong>, sur des diamètres de <strong class="description">17</strong> et pour un nombre de pneus de <strong class="description">4</strong>, avec une remise de <strong class="description">60€</strong> :</div>
                <code>season-allseasons_diameter-17_tirecount-4_60</code>
              </li>
              <li>
                <div>Pour une remise <strong class="description">Hiver, diamètre 16, 2 pneus : 30€</strong> et une autre de <strong class="description">4 saisons, diamètre 18, 4 pneus : 50€</strong> :</div>
                <code>season-winter_diameter-16_tirecount-2_30;season-allseasons_diameter-18_tirecount-4_50</code>
              </li>
            </ul>
            <p>
              Remarque : indiquer les montants <strong class="description">directement en HT</strong> puisqu'il s'agit d'une action commerciale B2B.<br>
              Remarque 2 : indiquer les remises avec décimales avec un point (.) en séparateur (ex: 7.5) et non pas avec une virgule (ex: 7,5).
            </p>

            <h4>Règles de calcul de remises sur les saisons et les diamètres</h4>
            <p>
              Il est possible de créer une action commerciale avec des règles de remise basée sur les saisons et les diamètres.<br>
              Dans ce cas, le montant total de la remise sera égal au nombre de pneus sélectionné multiplié par la remise.<br>
              Il faut activer en obligatoire les champs "Nombre de pneus", "Diamètre de pneu" et "Saison".<br>
              La syntaxe des remises est la suivante :<br>
              <code>season-[summer OU winter OU allseasons]_diameter-[15 OU 16 OU ...]_[VALEUR DE LA REMISE]</code><br>
              Il faut absolument suivre cet ordre (season puis diameter)<br>
              Par exemple : <code>season-winter_diameter-18_50</code> pour une remise de 50€ par pneu de diamètre 18 avec la saison hiver.<br>
              S'il y a plusieurs règles de ce type concernées, il faut les séparer par des <code>points-virgules (;)</code><br>
              Voici quelques exemples de règles de calcul pour ce type de remises :
            </p>
            <ul>
              <li>
                <div>Pour la saison <strong class="description">été</strong>, sur des diamètres de <strong class="description">15</strong> avec une remise de <strong class="description">15€</strong> par pneu :</div>
                <code>season-summer_diameter-15_15</code>
              </li>
              <li>
                <div>Pour la saison <strong class="description">hiver</strong>, sur des diamètres de <strong class="description">16</strong> avec une remise de <strong class="description">35€</strong> :</div>
                <code>season-winter_diameter-16_35</code>
              </li>
              <li>
                <div>Pour la saison <strong class="description">4 saisons</strong>, sur des diamètres de <strong class="description">17</strong> avec une remise de <strong class="description">60€</strong> :</div>
                <code>season-allseasons_diameter-17_60</code>
              </li>
              <li>
                <div>Pour une remise <strong class="description">Hiver, diamètre 16 de 30€ par pneu</strong> et une autre de <strong class="description">4 saisons, diamètre 18 de 50€ par pneu</strong> :</div>
                <code>season-winter_diameter-16_30;season-allseasons_diameter-18_50</code>
              </li>
            </ul>
            <p>
              Remarque : indiquer les montants <strong class="description">directement en HT</strong> puisqu'il s'agit d'une action commerciale B2B.
            </p>

            <hr>

            <h3>[ACTION COMMERCIALE TYPE 2] Avec utilisation d'un fichier de codes promotionnels</h3>

            <h4>Informations générales</h4>
            <p>
              Il faut sélectionner le type d'action commerciale <strong class="description">"Avec utilisation d'un fichier de codes promotionnels"</strong> afin de définir l'action commerciale comme une action commerciale utilisant un fichier avec des codes promotionnels.<br>
              Dans ce cas, le formulaire de création d'une action commerciale s'adapte et masque les champs devenus inutiles (budget, nombre d'actions immédiates, etc.).<br>
              Un champ spécifique à ce type d'action commerciale apparaît, où il faut déposer le fichier <strong class="description">dans un format .CSV</strong> contenant les différents codes promotionnels à vérifier (champ par ailleurs obligatoire).<br>
            </p>
            <strong class="description"><a href="./assets/exemple-fichier-codes-promos.csv"><i _ngcontent-elq-c183="" class="material-icons" style="color: #333;">cloud_download</i> : Fichier d'exemple .CSV bien formatté pour intégration de codes promotionnels</a></strong>

            <hr>

            <h3>[ACTION COMMERCIALE TYPE 3] Avec génération et saisie de codes promotionnels</h3>

            <h4>Informations générales</h4>
            <p>
              Il faut sélectionner le type d'action commerciale <strong class="description">"Avec génération et saisie de codes promotionnels"</strong>.<br>
              Ce type d'action commerciale permet de proposer la génération d'un code promotionnel lors de la création d'un premier accord.<br>
              Ensuite, grâce à un deuxième accord, il est possible de saisir le code généré préalablement.<br>
            </p>

            <h4>Remarques</h4>
            <ul>
              <li>Le code généré n'est saisissable qu'un jour après sa génération (+24h).</li>
            </ul>

            <hr>

            <h3>[ACTION COMMERCIALE TYPE 4] Avec génération d'un code, envoi SMS et saisie du code</h3>

            <h4>Informations générales</h4>
            <p>
              Il faut sélectionner le type d'action commerciale <strong class="description">"Avec génération d'un code, envoi SMS et saisie du code"</strong>.<br>
              Pour utiliser ce type d'action commerciale, il faut activer au minimum les champs suivants avec une saisie <strong class="description">obligatoire</strong> :
            </p>
            <ul>
              <li><strong class="description">"Téléphone portable"</strong> ;</li>
              <li><strong class="description">"Numéro facture"</strong> ;</li>
              <li><strong class="description">"Nombre de pneus"</strong> ;</li>
              <li><strong class="description">"Prix unitaire du pneu (TTC)"</strong> ;</li>
              <li><strong class="description">"Marque de pneu"</strong> ;</li>
            </ul>

            <h4>Principe / Fonctionnement</h4>
            <ul>
              <li>Un garage Profil Plus saisi un accord initial pour générer un code, en rentrant différentes informations.</li>
              <li>Un SMS est envoyé au client via le téléphone indiqué, contenant son code généré et le montant calculé de son bon d'achat.</li>
              <li>Un garage Profil Plus saisi un deuxième accord afin d'utiliser le code généré que le client a reçu par SMS.</li>
            </ul>

            <h4>Calcul du montant du bon d'achat et pourcentages associés aux marques</h4>
            <p>
              Le calcul du bon d'achat suit la règle suivante :<br>
              Montant du bon d'achat = <strong class="description">16.67% sur le (nombre de pneus indiqué * le prix unitaire (TTC))</strong>.<br>
            </p>

            <h4>Remarques</h4>
            <ul>
              <li>
                Le montant du bon d'achat ne pourra pas être supérieur à 120,00 € TTC, si le calcul entraîne un résultat supérieur à cette valeur, il sera ramené à 120,00 € TTC au max (et l'équivalent en HT).<br>
              </li>
              <li>Le code généré est saisisable immédiatement, à l'inverse de l'autre type d'action commerciale "Avec génération et saisie de codes promotionnels" où il faut attendre 24h.</li>
            </ul>

            <hr>

            <h3>Sélection des champs spécifiques de l'action commerciale</h3>

            <h4>Informations générales</h4>
            <p>
              En fonction du type d'action commerciale (B2C ou B2B), il est possible d'affecter différents champs spécifiques liés à l'action commerciale à créer, qui seront ensuite affichés dans le formulaire de création d'accord.<br>
              Le bloc de gauche se concentre sur les <strong class="description">champs d'informations liés aux clients </strong> (civilité, nom, prénom, email, etc).<br>
              Le bloc de droite concerne des <strong class="description">champs d'informations générales</strong> (nombre de pneus, diamètre de pneu, prix unitaire TTC, etc).
            </p>

            <h4>Remarques</h4>
            <ul>
              <li>Pour chaque champ, il est <strong class="description">possible d'indiquer si oui ou non le champ doit être obligatoirement</strong> rempli au moment de la création d'un accord.</li>
              <li>Indiquer les remises avec décimales avec un point (.) en séparateur (ex: 7.5) et non pas avec une virgule (ex: 7,5).</li>
            </ul>

            <hr>

            <h3>Envoi automatique des statistiques par emails</h3>
            <p>
              <strong class="description">Pour une action commerciale "Standard" ou "Avec génération d'un code, envoi SMS et saisie du code"</strong>, si cette case est cochée, les statistiques de cette action commerciale seront automatiquement envoyées aux administrateurs Profil Plus, aux adhérents et aux chargés d'accueil <strong class="description">tous les jours</strong> (sauf Yassin EL BARNOUSSI et Jean-Pierre LAUR).<br>
              <strong class="description">Pour une action commerciale "Avec utilisation d'un fichier de codes promotionnels" ou "Avec génération et saisie de codes promotionnels"</strong>, si cette case est cochée, seulement Aurore MARCEAU recevra un export des statistiques par email tous les lundis.<br>
              Sinon, il vous faudra faire un <strong class="description">export manuel</strong> au besoin, via l'onglet <strong class="description">"Vue Maître"</strong> et le bouton d'action <strong class="description">"Télécharger les statistiques"</strong>.<br>
            </p>
          </div>

          <!-- SALONS -->
          <div *ngIf="moduleSlug == 'meetings'">
            <h2>SECTIONS</h2>
            <ul>
              <li>Informations générales</li>
              <li>Création et édition d'un questionnaire</li>
              <li>Mise à jour de rubriques et/ou de questions</li>
              <li>Système d'export et d'import de contacts</li>
              <li>Système d'envoi documentaire</li>
              <li>Système de scan de cartes de visite</li>
            </ul>

            <h3>Informations générales</h3>
            <p>Il n'est pas possible de supprimer un questionnaire s'il a déjà des contacts associés.</p>

            <h3>Création et édition d'un questionnaire</h3>
            <p>Si un salon a lieu sur une seule journée, indiquer la même date dans les champs "Date de début" et "Date de fin".</p>
            <p>
              <strong>Ordre des cases à cocher pour les blocs "Société" et "Contact" + lien avec l'export Excel :</strong><br>
              L'ordre de cochage des cases à cocher dans les blocs "Société" et "Contact" détermine l'ordre des colonnes dans l'export Excel des statistiques du questionnaire concerné.<br>
              Il est donc judicieux de cocher les cases à cocher en gardant en tête l'ordre souhaité dans l'export Excel.
            </p>

            <h3>Mise à jour de rubriques et/ou de questions</h3>
            <p>
              Concernant la mise à jour d'un questionnaire et notamment de la partie rubriques / questions :<br>
              - Pensez à bien enregistrer chaque modification de question (bouton <strong class="description">"METTRE À JOUR LA QUESTION"</strong>)<br>
              - Pensez également à bien enregistrer chaque modification de rubrique (bouton <strong class="description">"METTRE À JOUR LA RUBRIQUE"</strong>)<br>
              - Enfin, cliquez sur le bouton <strong class="description">"METTRE A JOUR"</strong> du questionnaire en bas de page.<br>
              Si vous cliquez directement sur le bouton <strong class="description">"METTRE À JOUR"</strong>, les modifications liées aux rubriques / questions ne seront pas enregistrées.
            </p>

            <h3>Système d'export et d'import de contacts</h3>
            <p>
              Pour chaque questionnaire, il est possible de charger une liste de contacts via un fichier Excel (.xlsx).<br>
              Pour ce faire, aller sur la liste des questionnaires, puis dans les actions disponibles à droite du questionnaire, cliquer sur le bouton "Exporter le modèle d'import pour ce questionnaire".<br>
              Un fichier .xlsx va être téléchargé, qui contiendra les différentes colonnes du questionnaire concerné (champs "Société" + champs "Contact", pas les rubriques, ni les questions).<br>
              Il suffit ensuite de remplir ce fichier .xlsx avec les informations de contacts souhaitées.<br>
              Une fois rempli, il suffit de le charger via la liste des questionnaires et le bouton en haut à droite, <strong class="description">"IMPORT DE CONTACTS"</strong><br>
              Lorsque le traitement de l'import sera terminé, une notification sera affichée à l'utilisateur.
            </p>
            <p><strong class="description">Voici quelques règles et recommandations à respecter :</strong></p>
            <ul>
              <li>La colonne A ("questionnaire_id") doit toujours contenir le bon ID du questionnaire concerné, qui est toujours indiqué par défaut dans le modèle d'export. S'il faut ajouter de nouvelles lignes, il faut s'assurer de bien renseigner le même ID pour chaque ligne (un ID différent correspondrait à un questionnaire différent et pourrait entraîner des problèmes d'enregistrement de données).</li>
              <li>Ne pas toucher ni supprimer les lignes 1 et 2, elles sont importantes pour faire fonctionner le système d'import.</li>
              <li>
                Il faut s'assurer de remplir au mieux l'ensemble des colonnes.<br>
                Une colonne correspondant à un champ obligatoire sur le formulaire doit être remplie, sinon le système ne prendra pas en compte la ligne en intégralité.<br>
                De même, une colonne ne respectant pas le format nécessaire entraînera une erreur et le système ne prendra pas en compte la ligne en intégralité.<br>
                L'erreur sera ensuite listée dans un rapport d'erreurs accessible via l'onglet "Notifications" et la colonne "Rapport".
              </li>
              <li>Le champ "Prospect ou Client ?" n'accepte que les valeurs suivantes : <strong class="description">"Prospect" ou "Client"</strong></li>
              <li>Le champ "Responsable du compte" n'accepte pour valeur (unique) que l'une des valeurs de responsables renseignées dans la configuration du formulaire. Exemple : s'il a été configuré "Pierre Harry", "Kévin Truel" et "Paul Hasti", il faut indiquer une de ces valeurs seulement.</li>
              <li>Le champ "Civilité" n'accepte que les valeurs suivantes : <strong class="description">"Mr" ou "Mme"</strong></li>
              <li>Le champ "Téléphone portable" doit respecter le format suivant : <strong class="description">format +33 suivi de 9 chiffres (+33606060606) ou 10 chiffres (0606060606)</strong></li>
              <li>Le champ "Email" doit respecter le format suivant : <strong class="description">format email (... @ ... . ...)</strong></li>
              <li>Le champ "Êtes-vous : Prescripteur et/ou Décideur ?" n'accepte que les valeurs suivantes <strong class="description">"Prospect", "Client" ou "Prospect,Client" (les deux valeurs avec une virgule et sans espace, pour cocher les deux)</strong></li>
            </ul>
            <p>
              Dans le cas où surviennent des erreurs lors du traitement du fichier d'import, un fichier de rapport d'erreur est généré et disponible via l'onglet "Notifications".<br>
              Ce dernier contient 3 colonnes informatives : "Ligne", "Champs", "Erreur".<br>
              Il permet aux utilisateurs de voir les soucis rencontrés, de les corriger puis de charger de nouveau le fichier d'import corrigé afin de bien importer les lignes précédemment évitées à cause d'erreurs.
            </p>

            <h3>Système d'envoi documentaire</h3>
            <p>
              Sur chaque fiche contact, il est possible d'envoyer différents documents au contact et à d'autres destinaires optionnels via un système d'envoi documentaire.<br>
              Pour ce faire, cliquer sur <strong class="description">ENVOI DOCUMENTAIRE</strong> : une popin va apparaître.<br>
              Dans cette dernière :<br>
              - L'email du contact sera renseigné si l'information a déjà été saisie pour ce contact, sinon il sera saisissable.<br>
              - Le champ "Autres destinaires" peut être complété de différents emails séparés par un point virgule (";") : exemple "kevin@atnos.com;bruno@atnos.com".<br>
              - Le champ "Vos documents" permet de déposer un ou plusieurs documents.<br>
            </p>
            <p>
              Lorsque l'utilisateur clique sur <strong class="description">ENVOYER</strong>, l'email part vers le contact et les autres destinaires s'il y en a, avec en pièces jointes les documents.<br>
              Les statistiques de chaque questionnaire contiennent également les colonnes contenant les informations d'envois documentaires pour chaque ligne de contact (Envoi documentaire - Email du contact, Envoi documentaire - Autres destinataires, Envoi documentaire - Documents).
            </p>

            <h3>Système de scan de cartes de visite</h3>
            <p>
              Il est possible de scanner des cartes de visite et de pré-remplir les champs d'un formulaire de contact avec les informations scannées.<br>
              Pour ce faire, il y a deux boutons avec deux fonctionnements différents :<br>
              - <strong class="description">SCAN FORMULAIRE</strong> : vous permet de scanner une carte et de procéder à une vérification humaine avant de valider la création du contact.<br>
              - <strong class="description">SCAN RAPIDE</strong> : vous permet de scanner une carte et de créer le contact sans vérification humaine préalable (attention, risque d'erreurs de saisie).<br>
            </p>
            <p>
              Voici des recommandations afin d'optimiser les résultats du scan :<br>
              - Prendre la photo en étant le plus stable possible pour éviter de scanner des images floues.<br>
              - Avoir le navigateur de la tablette à jour (Chrome, Firefox, ...)<br>
              - Scanner la carte sur un fond uni.<br>
              - Scanner la carte de telle sorte que celle-ci soit bien cadrée au centre de l'écran et prenne le plus de place possible.<br>
              - Scanner la carte en étant le plus aligné par rapport à cette dernière.<br>
            </p>

          </div>

          <!-- SITE PROFIL PLUS -->
          <div *ngIf="moduleSlug == 'website'">
            <h2>SECTIONS</h2>
            <ul>
              <li>Mapping de fichiers</li>
            </ul>

            <h3>Mapping de fichiers</h3>
            <p>Afin d'assurer le bon fonctionnement des mappings de fichier, il faut s'assurer de respecter les points suivants :</p>
            <ul>
              <li>1. Fichiers utilisés et importés sur le Portail Profil Plus tous les deux en format Excel (.xlsx).</li>
              <li>2. Colonnes à respecter dans le "Fichier clients" : 5 colonnes : raison sociale, nom de l'agence, adresse, code postal et ville.</li>
              <li>3. Colonnes à respecter dans le "Fichier agences" (fichier réseau).</li>
            </ul>
            <strong class="description"><a href="./assets/modele-fichier-clients.xlsx"><i _ngcontent-elq-c183="" class="material-icons" style="color: #333;">cloud_download</i> : Fichier client d'exemple .xlsx</a></strong>
            <strong class="description"><a href="./assets/modele-fichier-agences.xlsx"><i _ngcontent-elq-c183="" class="material-icons" style="color: #333;">cloud_download</i> : Fichier agence (réseau) d'exemple .xlsx</a></strong>
          </div>

          <!-- MYPROFIL+ -->
          <div *ngIf="moduleSlug == 'myprofilplus'">
            <h2>SECTIONS</h2>
            <ul>
              <li>Cartes CE / VI + export</li>
              <li>Statistiques / Requêtes</li>
            </ul>

            <h3>Cartes CE / VI + export</h3>
            <p>Avec l'onglet "Cartes CE / VI", vous pouvez accéder aux cartes CE (cartes grands comptes) et aux cartes VI (cartes clients B2B adhérents).</p>
            <p>
              Vous pouvez également exporter les cartes CE / VI grâce au bouton <strong class="description">"EXPORT .CSV"</strong><br>
              Cet export sera généré à partir des filtres appliqués.
            </p>

            <h3>Statistiques / Requêtes</h3>
            <p>Vous avez accès, grâce à l'onglet "Statistiques / Requêtes" aux informations suivantes :</p>
            <ul>
              <li>Analyse fidélité - Fidélité générale</li>
              <li>Analyse fidélité - Fidélité par adhérent</li>
              <li>Analyse fidélité - Fidélité par agence</li>
              <li>Analyse fidélité - Echanges entre agences</li>
              <li>Requête - Comptabilité générale</li>
            </ul>
          </div>

          <!-- PROFIL PLUS DIRECT -->
          <div *ngIf="moduleSlug == 'profilplus-direct'">
            <p>Aucune aide disponible pour le moment.</p>
          </div>

          <!-- PROFIL PLUS AGENCES -->
          <div *ngIf="moduleSlug == 'profilplus-agences'">
            <h2>SECTIONS</h2>
            <ul>
              <li>Accéder à l'ensemble des informations d'une agence</li>
              <li>Gestion des services</li>
              <li>Export des agences</li>
              <li>Export PLV</li>
              <li>Export ADV</li>
              <li>Import de masse agences</li>
            </ul>

            <hr>

            <h3>Accéder à l'ensemble des informations d'une agence</h3>
            <p>
              À partir de la liste des agences, il est possible de consulter le détail d'une agence.<br>
              Cela permet d'accéder à différentes informations spécifiques à cette agence :
            </p>
            <ul>
              <li>Informations générales de l'agence</li>
              <li>Métiers associés</li>
              <li>Services associés</li>
              <li>Liste des dates de fermeture associées + création de nouvelles dates (+ gestion des fermetures exceptionnelles)</li>
              <li>Horaires d'ouverture magasin</li>
              <li>Liste des correspondants rattachés + création de nouveaux correspondants + rattachement des emails aux correspondants</li>
              <li>Pour les administrateurs : réglages avancés (fermeture définitive d'agence, ...)</li>
            </ul>

            <hr>

            <h3>Gestion des services</h3>
            <p>
              Via l'onglet "Services", il est possible de consulter la liste des services, de les éditer ou d'en créer de nouveaux.<br>
              Les champs "Visible sur le site B2C ?" et "Visible sur le site B2B ?" permettent d'indiquer si un service doit être affiché ou non dans les parcours devis et RDV du site profilplus.fr en mode B2C ou B2B.
            </p>

            <hr>

            <h3>Export des agences</h3>
            <p>À partir de la liste des agences, il est possible d'<strong class="description">exporter la liste des agences et leurs données</strong>.</p>
            <p>
              Si vous êtes un administrateur Profil Plus, vous exporterez l'ensemble des agences Profil Plus.<br>
              Si vous êtes un adhérent, vous exporterez la liste de vos agences.<br>
              Pour ce faire, il suffit de cliquer sur le bouton <strong class="description">"EXPORT AGENCES (MULTI)"</strong> présent en haut de la page.<br>
              L'export prend la forme d'un fichier Excel (.xlsx).
            </p>
            <p>Par ailleurs, vous pouvez retrouver votre export Agences dans l'onglet <strong class="description">"Notifications"</strong> présent dans le menu de gauche du portail Profil Plus.</p>

            <hr>

            <h3>Export PLV</h3>
            <p>À partir de la liste des agences, il est possible pour les administrateurs Profil Plus d'<strong class="description">obtenir l'export PLV des agences</strong>.</p>
            <p>
              Pour ce faire, cliquer sur le bouton <strong class="description">"EXPORT PLV"</strong> présent en haut de la page.<br>
              L'export prend la forme d'un fichier Excel (.xlsx).
            </p>
            <p>Par ailleurs, vous pouvez retrouver votre export PLV dans l'onglet <strong class="description">"Notifications"</strong> présent dans le menu de gauche du portail Profil Plus.</p>

            <hr>

            <h3>Export ADV</h3>
            <p>À partir de la liste des agences, il est possible pour les administrateurs Profil Plus d'<strong class="description">obtenir l'export ADV des agences</strong>.</p>
            <p>
              Pour ce faire, cliquer sur le bouton <strong class="description">"EXPORT ADV"</strong> présent en haut de la page.<br>
              L'export prend la forme d'un fichier Excel (.xlsx).
            </p>
            <p>Par ailleurs, vous pouvez retrouver votre export ADV dans l'onglet <strong class="description">"Notifications"</strong> présent dans le menu de gauche du portail Profil Plus.</p>

            <hr>

            <h3>Import de masse agences</h3>
            <p>À partir de la liste des agences, il est possible d'importer en masse et de mettre à jour les données des agences.</p>
            <p>Pour ce faire, il faut préalablement récupérer la liste des agences via l'export (voir section <strong class="description">"Export des agences"</strong>).</p>
            <p>Procéder ensuite à la mise à jour du fichier Excel (.xlsx).</p>

            <p><strong class="description">IL EST FORTEMENT RECOMMANDÉ D'UTILISER EXCEL POUR LA MISE À JOUR DE L'EXPORT.</strong></p>

            <p>
              Veuillez également vous assurer que <strong class="description">toutes les colonnes du fichier Excel soient bien formatées en type "Texte"</strong>.<br>
              Le cas échéant, il peut y avoir des soucis d'importation.<br>
              Sous Excel, suivre la procédure suivante :
            </p>
            <ul>
              <li>Sélectionner la ou les colonnes concernées</li>
              <li>Faire un clique-droit sur l'entête de la ou les colonnes sélectionnées</li>
              <li>Sélectionner la ou les colonnes concernées</li>
              <li>Cliquer sur "Format de cellule" (ou terme équivalent selon version d'Excel)</li>
              <li>Dans "Catégorie", sélectionner "Texte"</li>
            </ul>
            <p>Voir le lien suivant pour plus d'informations : <a href="https://support.microsoft.com/fr-fr/office/mettre-en-forme-les-nombres-en-tant-que-texte-583160db-936b-4e52-bdff-6f1863518ba4" target="_blank">Tutoriel de mise en format Texte</a></p>

            <p><strong class="description">VEUILLEZ RESPECTER LES FORMATS SUIVANTS :</strong></p>
            <p>Onglet <strong class="description">"Agences" :</strong></p>
            <ul>
              <li>Numéro de téléphone : doit contenir <strong class="description">exactement 10 chiffres sans espace</strong></li>
              <li>Fax : doit contenir <strong class="description">exactement 10 chiffres sans espace</strong></li>
              <li>SIRET : doit contenir <strong class="description">exactement 14 chiffres sans espace</strong></li>
              <li>TVA : doit respecter le format suivant : <strong class="description">"FR[11 chiffres]"</strong></li>
              <li>Latitude : doit contenir <strong class="description">un chiffre à décimal (pouvant aussi être négatif)</strong></li>
              <li>Longitude : doit contenir un <strong class="description">chiffre à décimal (pouvant aussi être négatif)</strong></li>
              <li>Email du chef d'agence : doit respecter un <strong class="description">format email (... @ ... . ...)</strong></li>
              <li>
                ERP : doit contenir exactement l'une des valeurs suivantes :<br>
                <strong class="description">"" (valeur vide), "WINPRO", "WCONTACT", "IBSM", "ERP", "CEGID", "PSI (EDP)"</strong>
              </li>
              <li>Date EPYX : doit respecter le format suivant : <strong class="description">JJ/MM/AAAA</strong> (exemple : 24/11/2017)</li>
              <li>Quantité Wincho pneu/EMA et autres champs quantités liés : doit contenir <strong class="description">un chiffre strictement supérieur ou égal à 0</strong></li>
            </ul>
            <p>Onglet <strong class="description">"Adresses" :</strong></p>
            <ul>
              <li>Code postal : doit contenir <strong class="description">strictement 5 chiffres</strong></li>
            </ul>
            <p>Onglet <strong class="description">"Services" :</strong></p>
            <ul>
              <li>
                Chaque service peut avoir la valeur "<strong class="description">oui</strong>", "<strong class="description">non</strong>" ou <strong class="description">une saisie libre</strong> (exemple "4 véhicules de service").<br>
                Dans le cas d'une saisie libre, le service comme coché (oui) et son champ complémentaire "Données complémentaires" sera rempli avec la valeur de la saisie libre.
              </li>
            </ul>
            <p>Onglet <strong class="description">"Dates de fermeture" :</strong></p>
            <ul>
              <li>
                Les différentes dates de fermeture doivent être strictement de la forme suivante :<br>
                - Un libellé contenant un texte libre que vous voulez dans une première colonne.<br>
                - La date dans la seconde colonne, avec le format <strong class="description">JJ/MM/AAAA</strong>.<br>
                Exemple : 1ère colonne "Fermeture pour XYZ pour raison ABC", 2nd colonne, "24/05/2021", etc...
              </li>
              <li>
                Vous pouvez déclarer autant de paires de dates de fermeture que vous voulez (une colonne pour un libellé suivie d'une colonne pour une date, etc...).<br>
                Si la date n'existe pas et qu'elle est dans le futur par rapport à la date courante, elle sera créée et enregistrée.<br>
                Si la date existe, alors seulement le libellé sera mis à jour.
              </li>
            </ul>
            <p>Onglet <strong class="description">"Horaires Magasin" :</strong></p>
            <ul>
              <li>Les différents horaires (lundi matin, après-midi, etc...) doivent être strictement de la forme suivante : <strong class="description">00:00-00:00, allant de 00 à 23 pour les heures et de 00 à 59 pour les minutes.</strong></li>
            </ul>
            <p>Onglet <strong class="description">"Correspondants" :</strong></p>
            <ul>
              <li>Email : doit respecter un <strong class="description">format email (... @ ... . ...)</strong></li>
              <li>Tel : doit contenir <strong class="description">exactement 10 chiffres sans espace</strong></li>
              <li>Mobile : doit contenir <strong class="description">exactement 10 chiffres sans espace</strong></li>
              <li>
                Rôle : doit contenir exactement l'une des valeurs suivantes :<br>
                <strong class="description">
                  "Responsable Marketing", "Devis TC4", "Commercial agricole",<br>
                  "Responsable PL", "Responsable Agri", "Responsable GC", "Responsable Manut"
                </strong>
              </li>
            </ul>
            <p>
              Une fois les modifications apportées, cliquer sur le bouton <strong class="description">"IMPORT DE MASSE"</strong> présent en haut à droite de la page.
              Une fenêtre vous demandera de déposer votre fichier à importer. Il s'agira du fichier exporté initialement, qui contient vos mises à jour.<br>
              Localisez-le et validez pour lancer le traitement. L'opération peut prendre plusieurs minutes selon le nombre d'agences impactées.
            </p>
            <p>
              Par ailleurs, vous pouvez voir si l'import est terminé via l'onglet <strong class="description">"Notifications"</strong> présent dans le menu de gauche du portail Profil Plus.<br>
              S'il est terminé, la colonne <strong class="description">"LIEN"</strong> contiendra un lien de téléchargement plutôt que la mention <strong class="description">"En cours"</strong>.
            </p>
          </div>


          <!-- HISTORIQUE EMAILS / SMS -->
          <div *ngIf="moduleSlug == 'history'">
            <p>Historique des diverses modifications effectuées.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
