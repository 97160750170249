<app-topbar moduleSlug="history">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/history']">Historique</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des historiques</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Historiques <span>({{ this.datatable.count }} éléments)</span>
        </h4>
      </div>
      <div class="card-body">

        <app-filter-bar [filters]="filters"
                        (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >

            <ngx-datatable-column name="Quoi" prop="auditable_type" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getTranslation(value) }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="ID" prop="auditable_id" [width]="110">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Date" prop="created_at" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'dd/MM/y HH:mm' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Action" prop="action" [width]="120" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getTranslation(value) }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Qui" prop="username" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Modifications" prop="handle_audited_changes" [width]="600" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let key of objectKeys(value)">
                  <strong>{{ getTranslation(key) }} :</strong>
                  <ul *ngIf="Array.isArray(value[key]); else notArray">
                    <li *ngFor="let val of value[key]">
                      {{ getTranslation(val) || '""' }}
                    </li>
                  </ul>
                  <ng-template #notArray>
                    {{ getTranslation(value[key]) }}
                  </ng-template>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="[ '/history/list', row.id, 'consultation' ]"
                   class="btn btn-success" title="Consulter">
                  <i class="material-icons">visibility</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>
