import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import { CommercialAction } from '../models/CommercialAction';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { Agreement } from '../models/Agreement';

function isFloat(num) {
  return num % 1 !== 0;
}


@Injectable({ providedIn: 'root' })
export class AgreementsService {
  constructor(private apiService: ApiService, private router: Router) {

  }

  getById(actionId, agreementId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/' + actionId + '/agreements/' + agreementId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  getAllByCommercialAction(actionId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/' + actionId + '/agreements/')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/agreements')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/agreements?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  edit(actionId: string | number, agreement) {
    return new Promise((resolve, reject) => {

      // Special case
      let axles: Array<{name:string, value:any}> = [];
      for (const [key, value] of Object.entries(agreement)) {
        if (key.includes('general_axle_') && agreement[key]) {
          axles.push({name: key, value:  Number(value)})
        }
      }

      const formData = new FormData();

      /* Customer general data */
      if (agreement.customer_siret && agreement.customer_siret.length > 0) {
        formData.append('customer:siret', agreement.customer_siret);
      }
      if (agreement.customer_loyalty_card_number && agreement.customer_loyalty_card_number.length > 0) {
        formData.append('customer:loyalty_card_number', agreement.customer_loyalty_card_number);
      }
      if (agreement.customer_first_name && agreement.customer_first_name.length > 0) {
        formData.append('customer:first_name', agreement.customer_first_name);
      }
      if (agreement.customer_last_name && agreement.customer_last_name.length > 0) {
        formData.append('customer:last_name', agreement.customer_last_name);
      }
      if (agreement.customer_email && agreement.customer_email.length > 0) {
        formData.append('customer:email', agreement.customer_email);
      }
      if (agreement.customer_civility) {
        formData.append('customer:civility', agreement.customer_civility);
      }
      if (agreement.customer_society && agreement.customer_society.length > 0) {
        formData.append('customer:society', agreement.customer_society);
      }
      if (agreement.customer_birthday && agreement.customer_birthday.length > 0) {
        formData.append('customer:birthday', agreement.customer_birthday);
      }
      if (agreement.customer_phone && agreement.customer_phone.length > 0) {
        formData.append('customer:phone', agreement.customer_phone);
      }

      /* Customer address data */
      if (agreement.customer_address && agreement.customer_address.length > 0) {
        formData.append('customer:street_name', agreement.customer_address);
      }
      if (agreement.customer_address_add && agreement.customer_address_add.length > 0) {
        formData.append('customer:street_name_more', agreement.customer_address_add);
      }
      if (agreement.customer_address_postal_code && agreement.customer_address_postal_code.length > 0) {
        formData.append('customer:zipcode', agreement.customer_address_postal_code);
      }
      if (agreement.customer_address_city && agreement.customer_address_city.length > 0) {
        formData.append('customer:city', agreement.customer_address_city);
      }

      if (agreement.customer_invoice_number && agreement.customer_invoice_number.length > 0) {
        formData.append('customer:invoice_number', agreement.customer_invoice_number);
      }
      if (agreement.customer_invoice_number_entered && agreement.customer_invoice_number_entered.length > 0) {
        formData.append('customer:invoice_number_entered', agreement.customer_invoice_number_entered);
      }

      /* Misc data */
      formData.append('commercial_action_agreement:agency_id', agreement.customer_agency);

      formData.append('commercial_action_agreement:data', JSON.stringify({
        general_tire_count: Number(agreement.general_tire_count),
        general_price_unit_ht: agreement.general_price_unit_ht,
        general_price_unit_ttc: agreement.general_price_unit_ttc,
        general_diameter: isFloat(agreement.general_diameter) ? parseFloat(agreement.general_diameter.toString()) : Number(agreement.general_diameter),
        general_width: agreement.general_width,
        general_tire_brand: agreement.general_tire_brand,
        general_vehicle_brand: agreement.general_vehicle_brand,
        general_fiscal_horse: agreement.general_fiscal_horse,
        general_axles: axles,
        general_season: agreement.general_season,
        general_promo_code: (agreement.promo_code && agreement.promo_code.length > 0) ? agreement.promo_code : null,
        entered_code_agency_id: agreement.entered_code_agency_id,
        entered_code_user_id: agreement.entered_code_user_id
      }));

      formData.append('commercial_action_agreement:user_id', agreement.user.id)

      if (agreement.id) {
        this.apiService.put('commercial_actions/' + actionId + '/agreements/' + agreement.id, formData)
        .then((response: Response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('commercial_actions/' + actionId + '/agreements', formData)
        .then((response: Response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  delete(actionId, agreementId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('commercial_actions/' + actionId + '/agreements/' + agreementId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
