import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public moduleSlug: string;
  public moduleNames = {
    'users-management': 'Utilisateurs',
    'adherents-companies-management': 'Adhérents / Sociétés',
    'commercial-actions': 'Actions commerciales',
    'meetings': 'Salons',
    'website': 'Site Profil Plus',
    'myprofilplus': 'MyProfil+',
    'profilplus-direct': 'Profil Plus Direct',
    'profilplus-agences': 'Profil Plus Agences',
    'history': 'Historique'
  };

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.moduleSlug = this.router.url.split('/')[1];
  }

}
